export const MODAL_TYPES = {
  become_partner: "jsModalClients",
  get_a_commercial_offer: "jsModalOffer",
  get_checklist: "jsModalCheckList", // ?
  fail_feedback: "jsFailFeedback",
  find_a_specialist: "jsModalSpecialist",
  order_a_consultation: "jsModalСonsultation",
  get_pricelist: "jsModalPriceList",
  event_register: "jsModalEventRegister",
  event_register_2: "jsModalEventRegisterTwo",
  get_webinar_record: "jsModalWebinarRecord",
  discuss_project: "jsModalDiscussProject",
  get_commercial_offer_onec: "jsModalOfferOneC",
  get_onec_erp_demo: "jsModalGetOneCErpDemo",
  onec_services: "jsModalOneCServices",

  success_feedback: "jsSuccessFeedback",
  success_subscribe: "jsSuccessSubscribe",
  success_offer: "jsSuccessOffer",
  success_resume: "jsSuccessResume",
  success_price_list: "jsSuccessPriceList",
  success_block: "jsSuccessBlock",
  success_webinar: "jsSuccessWebinar",
}

export const FORM_TYPES = {
  become_partner: "jsFormClients",
  get_a_commercial_offer: "jsFormOffer",
  get_checklist: "jsFormCheckList",
  get_pricelist: "jsFormPriceList",
  find_a_specialist: "jsFormSpecialist",
  order_a_consultation: "jsFormСonsultation",
  subscribe: "jsSubscribeForm",
  event_register: "jsFormEventRegister",
  event_register_2: "jsFormEventRegisterTwo",
  get_onec_course_plan: "jsFormOneCCoursePlan",
  get_webinar_record: "jsFormWebinarRecord",
  discuss_project: "jsDiscussProject",
  get_commercial_offer_onec: "jsFormOfferOneC",
  get_onec_erp_demo: "jsFormGetOneCErpDemo",
  become_rnd_partner: "jsRndForm",
  information_security: "jsInformationSafetyForm",
  our_decision: "jsInformationSafetyCommentForm",
  onec_services: "jsOneCServicesForm",
}

export const COUNTRIES_LIST = {
  by: "BY",
  ru: "RU"
}

export const COUNTRIES_CHAR = {
  by: '3',
  ru: '7'
}

export const PHONE_MASKS_LIST = {
  default: "+0",
  by: "+{375} 00 000-00-00",
  ru: "+{7} 000 000-00-00"
}

export const FORM_VALIDATE_RULES = {
  email: /^[A-Za-z0-9][A-Za-z0-9-_\.]+@([A-Za-z]|[A-Za-z0-9]?[A-Za-z0-9-]+[A-Za-z0-9])\.[A-Za-z0-9]{2,10}(?:\.[A-Za-z]{2,10})?$/,
  phone: /^[+]*[0-9]{1,3}\s[0-9]{2,3}\s[0-9]{3}-[0-9]{2}-[0-9]{2}/g,
  name: /^\D+$/gi
}

export const INPUTS_ERRORS = {
  email: "Введен некорректный email",
  file_size: "Вложение не может быть более 25MB",
  file_type: "Некорректный тип файла",
  name: "Введено некорректное имя",
  phone: "Введен некорректный номер телефона",
  required: "Обязательное поле"
}

export const API_URL = "/api";

export const FILE_TYPES = [
  ".doc",
  ".docx",
  ".xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "zip",
  "application/octet-stream",
  "application/zip",
  "application/x-zip",
  "application/x-zip-compressed",
  "application/rar",
  "application/7z",
  ".zip",
  ".rar",
  ".7z"
]

export const KEY = {
  escape: 27,
}

// export const CAPTCHA_KEY = 'ysc1_bf2yHh7XyhLf0gHEiLdkEg3eGTwSGyGeD7LDA38vdc4a0199';

export const UTM_PARAMETERS = new Set([
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]);

export const UTM_PARAMS_STORAGE_KEY = 'utm_params';
